const DATA_TEST_IDS = {
  CONTACT_NAME: 'contact-name',
  CONTACT_FIRST_NAME: 'contact-first-name',
  CONTACT_LAST_NAME: 'contact-last-name',
  CONTACT_PHONE_NUMBER: 'contact-phone-number',
  CONTACT_SECOND_PHONE_NUMBER: 'contact-second-phone-number',
  CONTACT_EMAIL: 'contact-email',
  CONTACT_ADDRESS_COMPONENT: 'contact-address-component',
  ACCOUNT_HOLDER: 'account-holder',
  ACCOUNT_IBAN: 'account-iban',
  ACCOUNT_BANK_NAME: 'account-bank-name',
  MONTHLY_RATE: 'monthly-rate',
  NEW_REMAINING_AMOUNT: 'new-remaining-amount',
  NEW_MONTHLY_RATE: 'new-monthly-rate',
  PAYMENT_HOLDER: 'payment-holder',
  PAYMENT_IBAN: 'payment-iban',
  PAYMENT_BANK_NAME: 'payment-bank-name',
  REMAINING_AMOUNT: 'remaining-amount',
  REPAYMENT_REMAINING_AMOUNT: 'repayment-remaining-amount',
  REPAYMENT_MONTHLY_RATE: 'repayment-monthly-rate',
  DURATION: 'duration',
  CUSTOMER_NUMBER: 'customer-number',
  PRIMARY_CONTACT: 'primary-contact-component',
  SECONDARY_CONTACT: 'secondary-contact-',
  TAXATION: 'taxation',
  TAX_NUMBER: 'tax-number',
  CHANGE_PASSWORD_BUTTON: 'change-password-button',
  PRIMARY_CONTACT_EDIT_EMAIL_CANCEL: 'primary-contact-edit-email-cancel',
  PRIMARY_CONTACT_EDIT_EMAIL_CONFIRM: 'primary-contact-edit-email-confirm',
  CONTACT_EDIT_VALIDATION_ERROR_EMAIL: 'contact-edit-validation-error-mail',
  CONTACT_REMOVE_BUTTON: 'contact-remove',
  SECONDARY_CONTACT_REMOVE_CONFIRM_BUTTON: 'secondary-contact-remove-confirm',
  SECONDARY_CONTACT_REMOVE_CANCEL_BUTTON: 'secondary-contact-remove-cancel',
  FILE_NAME_STRING: 'file-name-string',
  EXTERNAL_LINK_TO_ENPAL: 'external-link-to-enpal',
  IPO_ADDRESS_COMPONENT: 'ipo-address-component',
  ENERGY_ADDRESS_COMPONENT: 'energy-address-component',
  ENERGY_CONTRACT_NUMBER: 'energy-contract-number',
  ENERGY_CONTRACT_MONTHLY_RATE: 'energy-contract-monthly-rate',
  ENERGY_CONTRACT_CANCELLATION_OR_WITHDRAW_MODAL: 'energy-contract-cancellation-or-withdraw-modal',
  ENERGY_CONTRACT_CANCELLATION_OR_WITHDRAW_BUTTON: 'energy-contract-cancel-or-withdraw-button',
  ENERGY_CONTRACT_CLOSE_CANCELLATION_OR_WITHDRAW_MODAL_BUTTON: 'close-cancel-or-withdraw-contract-modal-button',
  ENERGY_CONTRACT_START_DATE: 'energy-contract-start-date',
  ENERGY_CONTRACT_MONTHLY_RATE_EDIT_CLOSE_MODAL: 'energy-contract-monthly-rate_close-modal-button',
  ENERGY_CONTRACT_MONTHLY_RATE_EDIT_OPEN_MODAL: 'energy-contract-monthly-rate_open-modal-button',

}

export default DATA_TEST_IDS
