'use client'
// @todo explore Browser logs collection
// https://docs.datadoghq.com/logs/log_collection/javascript/
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const clientToken = globalThis.process.env.ENPAL_PUBLIC_DD_CLIENT_TOKEN
const applicationId = globalThis.process.env.ENPAL_PUBLIC_DD_APPLICATION_ID
const env = globalThis.process.env.ENPAL_PUBLIC_DD_ENV
const site = 'datadoghq.eu'

if (!applicationId) throw new Error('Missing DD application ID')
if (!clientToken) throw new Error('Missing DD client token')

datadogLogs.init({
  clientToken: clientToken,
  service: process.env.appName,
  version: process.env.appVersion,
  env,
  site: site,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sessionSampleRate: 100,
})

datadogRum.init({
  applicationId,
  clientToken,
  site,
  service: process.env.appName,
  version: process.env.appVersion,
  env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  actionNameAttribute: 'data-testid',
  allowedTracingUrls: [
    /https:\/\/.*\.azurewebsites\.com/,
    (url) => url.startsWith('https://api-dev.core.enpal.io'),
    (url) => url.startsWith('https://api-stg.core.enpal.io'),
    (url) => url.startsWith('https://api.core.enpal.io'),
  ],
})
