import React, { useState } from 'react'
import Modal from './'

type Options = { closable: boolean }
type OpenModal = (
  modal: React.ReactNode,
  callback?: (data: unknown) => void | Promise<void>,
  options?: Options
) => void

const defaultValues = {
  closing: false,
  callback: (() => undefined) as (data?: unknown) => void | Promise<void>,
  closable: true,
  closeModal: (() => undefined) as (data?: unknown) => void,
  fullWin: false,
  isOpen: false,
  modalContent: undefined as React.ReactNode,
  openModal: (() => undefined) as OpenModal,
  setFullWin: (() => undefined) as (active: boolean) => void,
}

function useModal(): typeof defaultValues {
  const [isOpen, setIsOpen] = useState(defaultValues.isOpen)
  const [modalContent, setModalContent] = useState<React.ReactNode>(
    defaultValues.modalContent
  )
  const [callback, setCallback] = useState<typeof defaultValues.callback>(
    defaultValues.callback
  )
  const [fullWin, setFullWin] = useState(defaultValues.fullWin)
  const [closable, setClosable] = useState<boolean>(defaultValues.closable)
  const [closing, setClosing] = useState(defaultValues.closing)

  const openModal: OpenModal = (modal, callback, options = {} as Options) => {
    if (!modal) return

    setModalContent(modal)
    if (callback) {
      setCallback(() => callback)
    }
    if (options.closable !== undefined) {
      setClosable(options.closable)
    }
    if (!isOpen) {
      setIsOpen(true)
    }
  }

  async function closeModal<T>(data: T | unknown) {
    setClosing(true)
    if (callback) {
      await callback(data)
    }
    setIsOpen(false)
    setClosing(false)
    setClosable(defaultValues.closable)
    setFullWin(defaultValues.fullWin)
  }

  return {
    closing,
    modalContent,
    isOpen,
    openModal,
    closeModal,
    callback,
    setFullWin,
    fullWin,
    closable,
  }
}

const ModalContext = React.createContext(defaultValues)
const ErrorModalContext = React.createContext(defaultValues)

const ModalProvider = ({ children }) => {
  const modalState = useModal()
  const errorModalState = useModal()

  return (
    <ModalContext.Provider value={modalState}>
      <ErrorModalContext.Provider value={errorModalState}>
        <Modal context={ErrorModalContext} />
        <Modal context={ModalContext} />
        {children}
      </ErrorModalContext.Provider>
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider, ErrorModalContext }
