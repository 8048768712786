import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/common/Toast/AppToast.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/utils/datadog.ts");
