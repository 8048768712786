'use client'
import React from 'react'
import { ToastContainer, ToastPosition, cssTransition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface AppToastProps {
  position: ToastPosition
}

const AppToast = ({ position }: AppToastProps) => {
  return (
    <ToastContainer
      position={position}
      hideProgressBar
      autoClose={3000}
      newestOnTop
      draggable={false}
      closeOnClick
      rtl={false}
      theme="colored"
      transition={cssTransition({
        enter: 'zoomIn',
        exit: 'zoomOut',
        collapse: false,
        collapseDuration: 0,
      })}
    />
  )
}

export default AppToast
