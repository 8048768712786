'use client'

import { ApolloProvider } from '@apollo/client'
import { client } from 'client'
import { ModalProvider } from 'components/common/Modals/ModalContext'
import { SessionProvider, useSession } from 'next-auth/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useEffect, useState } from 'react'
import { getClientSideQueryClient } from 'reactQuery/client'
import React from 'react'

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

export default function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => getClientSideQueryClient())
  const [showDevtools, setShowDevtools] = React.useState(false)

  React.useEffect(() => {
    // @ts-expect-error: this will be used with the browser console
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
        <ApolloProvider client={client}>
          <TrackingProvider>
            <ModalProvider>{children}</ModalProvider>
          </TrackingProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </SessionProvider>
  )
}

/**
 * Provider component to initialize the client side tracking.
 *
 * @param param0 The children to render.
 * @returns The rendered children, wrapped with the tracking provider.
 */
const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
  /** Access to the user session. */
  const session = useSession()

  /** When the user navigates, update the tracking context. */
  useEffect(() => {
    if (!window || !window.dataLayer) {
      // If the data layer is not available, do nothing.
      return
    }
    const userId = session.data?.user.id

    if (!userId) {
      // If the user id is not available, do nothing.
      return
    }
    if (window.dataLayer.some((item) => item.user_id === userId)) {
      // If there is already an element with the user id present, skip.
      return
    }
    window.dataLayer.push({
      user_id: userId,
    })
  }, [session.data?.user.id])

  return <>{children}</>
}
